import React from 'react';
import {
  Container,
  Link,
  Typography,
  List,
  ListItem,
  Grid,
  Box,
  Paper,
  Button,
} from '@mui/material';
import { useAuthStore } from '../../../../stores/useAuthStore.js';
import venmo from '../../../../assets/Venmo_Logo_Blue.png';
export default function LandingPageInfo({ isMobile, handleSignInToSubscribe, handleStageChange }) {
  const features = [
    'Create your own gallery and profile page with the ability to create content.',
    'Keep track of post inventory valuations over time. Download CSV file from your dashboard for spreadsheets, etc. Track orders and print invoices.',
    'Set goals for monthly and daily quotas to manage your production.',
    'See how your business is doing at a glance with a color coded calendar. You can always review what you made, when, and for how much $ to stay consistent with your product.',
    'Monthly analysis with user data based graphs.',
    'Peace of mind that your data is encrypted with AES-256 encryption- no one is stealing your data. ',
  ];

  const { isAuthenticated } = useAuthStore();

  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: {
          xs: 2,
          sm: 3,
        },
        alignItems: 'flex-start',
        padding: '0px',
      }}
    >
      {/* Bulleted List of Features */}
      <Box
        sx={{
          borderBottom: '1px solid ',
          padding: '0px',
        }}
      >
        {' '}
        <Typography
          variant="h5"
          textAlign="left"
          sx={{ letterSpacing: '.1rem', marginBottom: '10px' }}
        >
          Our platform offers:
        </Typography>
        <Box
          sx={{
            height: isMobile ? '50vh' : 'content',
            overflowY: isMobile ? 'scroll' : '',
            backgroundColor: (theme) => theme.palette.primary.dark,
          }}
        >
          <List sx={{ listStyleType: 'disc', margin: '0 0 0 20px', padding: '10px' }}>
            {features.map((feature, index) => (
              <ListItem key={index} sx={{ display: 'list-item' }}>
                <Typography variant="body1" sx={{ letterSpacing: '.1rem' }}>
                  {feature}
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Paper sx={{ padding: '15px 20px', mt: '0px' }}>
        <Typography variant="body1" textAlign="left" sx={{ letterSpacing: '.1rem' }}>
          Why subscribe? We designed this website to be a powerful tool for creators to help
          organize and run their businesses, so they can lighten the load of trying to keep track of
          everything so they can focus more on what matters: making art.
        </Typography>
      </Paper>
      {isMobile ? (
        <Grid container sx={{ padding: '0px' }}>
          {' '}
          <Grid item xs={12} sx={{}}>
            <Box textAlign="center">
              {isAuthenticated ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleStageChange}
                  sx={{ fontSize: '1.5rem', mt: '25px' }}
                >
                  Purchase a Subscription
                </Button>
              ) : (
                <Button color="primary" variant="contained" onClick={handleSignInToSubscribe}>
                  Sign-up to Subscribe
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      <Typography sx={{ textAlign: 'center', width: '100%' }}>
        Your subscription will be handled securely and directly through{' '}
        <Link
          href="https://stripe.com/"
          target="_blank"
          rel="noopener"
          sx={{ textDecoration: 'none' }}
        >
          Stripe
        </Link>
        .
      </Typography>
      <Box>
        <Typography variant="body2" sx={{ width: '85%', margin: 'auto' }}>
          This platform could use your support! If you&apos;d like to help us get off the ground
          while we work out the bugs and build this out, we&apos;d be grateful. You can support via{' '}
          <Link
            sx={{ position: 'relative', top: '2.5px' }}
            href={'https://venmo.com/u/Kevin-Nail-1'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={venmo} width="67px" alt="Donate via Venmo" />
          </Link>
        </Typography>
      </Box>
    </Container>
  );
}
