import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Box,
  Button,
  useTheme,
  TableContainer,
  Select,
  MenuItem,
} from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import { useMediaQuery } from '@mui/material';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper } from '@mui/material';
import { Chart } from 'chart.js';
import 'chartjs-plugin-annotation';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useInventory } from '../../hooks/useInventory.js';

import FlamePipe from '../FlamePipe/FlamePipe.js';
import trackingExampleMobile from '../../assets/inventory-tracking-ex-m.png';
import trackingExampleDesktop from '../../assets/inventory-tracking-ex-dt.png';
import SaveIcon from '@mui/icons-material/Save';
import usePostStore from '../../stores/usePostStore.js';
import { useAuthStore } from '../../stores/useAuthStore.js';
Chart.register(annotationPlugin);

const InventoryTracking = ({ handleSaveSnapshot, tableData, setTableData, snapshots }) => {
  //

  // state ====================================================================

  const { inventoryLoading } = useInventory();
  const theme = useTheme(); // This is needed to access the default theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // 'sm' refers to small screens
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // 'md' refers to medium screens
  // const { restricted } = usePosts();
  const { restricted } = usePostStore();

  const { authenticateUser, isAuthenticated } = useAuthStore();

  const [timeScale, setTimeScale] = useState('daily'); // Default to 'daily'

  let initialDate;
  const localDate = new Date(); // Replace this with your specific date if needed
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Prepare chart data
  // Get the first date in your actual data (if it exists)
  // Calculate the data points for the graph

  // Get the sorted keys (dates) from tableData to ensure firstDate is properly calculated
  const sortedDates = Object.keys(tableData).sort((a, b) => new Date(a) - new Date(b));
  const firstDate = sortedDates.length > 0 ? sortedDates[0] : null;
  let chartDataPoints = [];

  // If there is a first date, we add an initial 0 value for graphing purposes
  if (firstDate) {
    chartDataPoints.push(0); // Add the initial 0

    // Add the rest of the data points by iterating over sortedDates
    sortedDates.forEach((date) => {
      const totalValue = Object.values(tableData[date]).reduce((total, value) => total + value, 0);
      chartDataPoints.push(totalValue);
    });
  } else {
    // If no first date, just use the sortedDates data points
    sortedDates.forEach((date) => {
      const totalValue = Object.values(tableData[date]).reduce((total, value) => total + value, 0);
      chartDataPoints.push(totalValue);
    });
  }
  if (firstDate) {
    const firstDateObj = new Date(firstDate);

    switch (timeScale) {
      case 'daily':
        initialDate = new Date(firstDateObj.setDate(firstDateObj.getDate() - 1)).toLocaleDateString(
          'en-US'
        );
        break;
      case 'weekly':
        initialDate = new Date(firstDateObj.setDate(firstDateObj.getDate() - 7)).toLocaleDateString(
          'en-US'
        );
        break;
      case 'monthly':
        initialDate = new Date(
          firstDateObj.setMonth(firstDateObj.getMonth() - 1)
        ).toLocaleDateString('en-US');
        break;
      case 'yearly':
        initialDate = new Date(
          firstDateObj.setFullYear(firstDateObj.getFullYear() - 1)
        ).toLocaleDateString('en-US');
        break;
      default:
        initialDate = new Date(firstDateObj.setDate(firstDateObj.getDate() - 1)).toLocaleDateString(
          'en-US'
        );
    }
  }

  // Prepare chart data
  const chartLabels = firstDate ? [initialDate, ...sortedDates] : sortedDates;

  const chartData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Total Inventory Value',
        data: chartDataPoints,
        borderColor: 'rgb(75, 192, 192)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
    ],
  };

  // functions ====================================================================

  const handleTypeChange = (event) => {
    setTimeScale(event.target.value);
  };

  const aggregateDataByTimeScale = (snapshots, timeScale) => {
    const aggregatedData = {};

    snapshots?.forEach((snapshot) => {
      const utcDate = new Date(snapshot.created_at);
      const timezoneOffset = utcDate.getTimezoneOffset() * 60000;
      const localDate = new Date(utcDate.getTime() + timezoneOffset);

      let key;
      switch (timeScale) {
        case 'daily': {
          key = localDate.toLocaleDateString('en-US');
          break;
        }
        case 'weekly': {
          const weekStart = new Date(localDate.setDate(localDate.getDate() - localDate.getDay()));
          key = weekStart.toLocaleDateString('en-US');
          break;
        }
        case 'monthly': {
          key = `${monthNames[localDate.getMonth()]} ${localDate.getFullYear()}`;
          break;
        }
        case 'yearly': {
          key = `${localDate.getFullYear()}`;
          break;
        }
        default: {
          key = localDate.toLocaleDateString('en-US');
        }
      }

      // Initialize the date entry if it doesn't exist
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          values: {}, // Store the aggregated values for each category here
          count: 0, // Track how many entries contribute to this period for averaging
        };
      }

      // Iterate over the `price_count` object and aggregate by category
      const priceCount = snapshot.price_count;
      if (typeof priceCount === 'object') {
        for (const [category, value] of Object.entries(priceCount)) {
          // skip empty or undefined categories to prevent empty table data

          if (!category) continue;

          if (!aggregatedData[key].values[category]) {
            aggregatedData[key].values[category] = 0; // Start with zero for each category
          }
          aggregatedData[key].values[category] += value ? value : 0;
        }
      } else {
        if (!aggregatedData[key].values['default']) {
          aggregatedData[key].values['default'] = 0;
        }
        aggregatedData[key].values['default'] += priceCount ? priceCount : 0;
      }

      aggregatedData[key].count += 1; // Increment the count for averaging
    });

    // Calculate the average for each category
    // Calculate totals for daily or averages for other time scales
    const processedData = {};
    for (const [key, { values, count }] of Object.entries(aggregatedData)) {
      processedData[key] = {};
      for (const [category, totalValue] of Object.entries(values)) {
        if (timeScale === 'daily') {
          // For daily, use the pure sum
          processedData[key][category] = totalValue;
        } else {
          // For weekly/monthly/yearly, calculate average
          processedData[key][category] = Math.max(totalValue / count, 0);
        }
      }
    }

    return processedData; // Corrected: Return the averaged data
  };

  useEffect(() => {
    const aggregatedData = aggregateDataByTimeScale(snapshots, timeScale);

    setTableData(aggregatedData); // Update the table data with aggregated data
  }, [snapshots, timeScale, setTableData]); // Run this effect when snapshots or time scale changes

  useEffect(() => {
    if (snapshots && snapshots.length > 0) {
      if (timeScale === 'raw') {
        // Use raw snapshots data (original functionality)
        const rawData = {};
        snapshots.forEach((snapshot) => {
          const utcDate = new Date(snapshot.created_at);
          const timezoneOffset = utcDate.getTimezoneOffset() * 60000;
          const localDate = new Date(utcDate.getTime() + timezoneOffset);
          const key = localDate.toLocaleDateString('en-US');

          if (!rawData[key]) {
            rawData[key] = 0;
          }
          rawData[key] += snapshot.price_count;
        });
        setTableData(rawData); // Set raw data for initial rendering
      } else {
        // Use the new aggregation logic
        const averagedData = aggregateDataByTimeScale(snapshots, timeScale);
        setTableData(averagedData);
      }
    }
  }, [snapshots, timeScale, setTableData]);

  // check auth
  useEffect(() => {
    if (!isAuthenticated) {
      authenticateUser();
    }
  }, []);

  // Extract unique categories from all snapshots
  const categories = new Set();

  snapshots &&
    snapshots?.forEach((snapshot) => {
      Object.keys(snapshot.category_count).forEach((category) => categories.add(category));
    });

  // display options for the chart
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        padding: 40,
        labels: {
          font: {
            size: isMobile ? 12 : 20, // Adjust the size for mobile
          },
          padding: isMobile ? 10 : 20, // Adjust padding for mobile
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          font: {
            size: isMobile ? 12 : 20,
          },
        },
        grid: {
          color: '#CCCCCC', // Lighter color for better visibility
          lineWidth: 1, // Adjust line width as needed
          // Optional: If you want dashed lines
          // borderDash: [5, 5],
        },
      },
      x: {
        ticks: {
          font: {
            size: isMobile ? 12 : 16,
          },
        },
        grid: {
          color: '#CCCCCC', // Lighter color for better visibility
          lineWidth: 1, // Adjust line width as needed
          // Optional: If you want dashed lines
          // borderDash: [5, 5],
          drawOnChartArea: true, // Ensure that grid lines are drawn on the chart area
        },
      },
    },
    elements: {
      line: {
        tension: 0.3, // Smoothness of the line
      },
    },
  };

  const calculateTotalForDate = (date) => {
    return Array.from(categories).reduce((total, category) => {
      return total + (tableData[date]?.[category] || 0);
    }, 0);
  };

  return inventoryLoading ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        margin: !isMobile && '150px',
      }}
    >
      <Typography variant="h5" sx={{ color: (theme) => theme.palette.primary.light }}>
        Loading Post Tracking <span className="animated-ellipsis">.</span>
        <span className="animated-ellipsis">.</span>
        <span className="animated-ellipsis ">.</span>
      </Typography>
      <FlamePipe />
    </Box>
  ) : Object.keys(tableData).length === 0 ? (
    <>
      {' '}
      {/* first initial snapshot */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Button
          variant={'contained'}
          onClick={handleSaveSnapshot}
          sx={{ marginBottom: '15px' }}
          disabled={restricted ? restricted : false}
        >
          {restricted ? 'Current Snapshot disabled' : 'Save First Current Snapshot'}
        </Button>
        <Typography sx={{ width: '70%', textAlign: 'left' }}>
          No data saved to display yet. The Inventory Tracking tab will show the total value of your
          public posts in the Dashboard tab over time as you choose to save a snapshot of the value
          on a particular day. You can then view a daily, weekly, monthly, or yearly summary with an
          updated table of the data. Example display below:
        </Typography>
        {isMobile ? (
          <img src={trackingExampleMobile} alt="site logo" width="50%" height="50%" />
        ) : (
          <img src={trackingExampleDesktop} alt="site logo" width="70%" height="50%" />
        )}
      </Box>{' '}
    </>
  ) : (
    <Box
      sx={{
        border: '1px solid white',
        borderColor: (theme) => theme.palette.primary.dark,
        padding: '15px',
        transform: 'translateY(-3%)',
      }}
    >
      {/* perpetual/ new snapshots */}
      <Box
        sx={{
          display: isTablet ? 'flex' : 'grid',
          flexDirection: isTablet ? 'column' : undefined,
          gridTemplateRows: isTablet ? undefined : '1fr',
          gridTemplateColumns: isTablet ? undefined : '1fr 1fr',
        }}
      >
        {isMobile ? (
          <Box sx={{ marginBottom: '1rem', display: 'flex', justifyContent: 'space-between' }}>
            {' '}
            <Typography sx={{ textAlign: 'left', paddingLeft: '10px', fontSize: '.9rem' }}>
              Select time scale for display
            </Typography>
            <Select sx={{ width: '145px' }} value={timeScale} onChange={handleTypeChange}>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="yearly">Yearly</MenuItem>
            </Select>
          </Box>
        ) : (
          <Box sx={{ padding: '0px', marginBottom: '1rem' }}>
            <Button
              sx={{ '& .MuiButton-root': { margin: '0px' } }}
              onClick={() => setTimeScale('daily')}
              variant={timeScale === 'daily' ? 'contained' : 'outlined'}
            >
              Daily
            </Button>
            <Button
              sx={{ margin: 0 }}
              onClick={() => setTimeScale('weekly')}
              variant={timeScale === 'weekly' ? 'contained' : 'outlined'}
            >
              Weekly
            </Button>
            <Button
              sx={{ margin: 0 }}
              onClick={() => setTimeScale('monthly')}
              variant={timeScale === 'monthly' ? 'contained' : 'outlined'}
            >
              Monthly
            </Button>
            <Button
              sx={{ margin: 0 }}
              onClick={() => setTimeScale('yearly')}
              variant={timeScale === 'yearly' ? 'contained' : 'outlined'}
            >
              Yearly
            </Button>
          </Box>
        )}

        <Button
          variant={'contained'}
          onClick={handleSaveSnapshot}
          sx={{ marginBottom: '15px', maxWidth: '275px', alignSelf: isTablet ? 'center' : '' }}
          disabled={restricted ? restricted : false}
          startIcon={<SaveIcon />}
        >
          {restricted ? 'Current Snapshot disabled' : 'Save Current Snapshot'}
        </Button>
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6} component={Paper}>
          <Line data={chartData} options={options} height={200}></Line>
        </Grid>
        <Grid item xs={12} lg={6}>
          <TableContainer component={Paper}>
            <Table aria-label="inventory table" size="small">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="span" sx={{ fontSize: '.8rem', padding: '0' }}>
                      Category
                    </Typography>
                  </TableCell>
                  {Object.keys(tableData).map((date, index) => (
                    <TableCell key={index} align="right">
                      <Typography variant="span" sx={{ fontSize: '.8rem', padding: '0' }}>
                        {date}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.from(categories).map((category, index) => (
                  <TableRow key={index} sx={{ height: '30px' }}>
                    <TableCell component="th" scope="row" sx={{ fontSize: '.7rem' }}>
                      {category}
                    </TableCell>
                    {Object.keys(tableData).map((date, idx) => (
                      <TableCell key={idx} align="right" sx={{ fontSize: '.7rem' }}>
                        $
                        {Number(tableData[date]?.[category] || 0).toLocaleString(undefined, {
                          maximumFractionDigits: 0,
                        })}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}

                {/* Grand Totals Row */}
                <TableRow sx={{ height: '30px' }}>
                  <TableCell
                    component="th"
                    scope="row"
                    align="left"
                    sx={{ fontWeight: 'bold', fontSize: '.8rem' }}
                  >
                    Grand Totals
                  </TableCell>
                  {Object.keys(tableData).map((date, index) => (
                    <TableCell
                      key={index}
                      align="right"
                      sx={{ fontWeight: 'bold', fontSize: '.8rem' }}
                    >
                      ${Number(calculateTotalForDate(date).toFixed(0)).toLocaleString()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
export default InventoryTracking;
