import React from 'react';
import useAppBar from '../../../hooks/useAppBar.js';
import { Avatar, Box, IconButton, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import useProfileStore from '../../../stores/useProfileStore.js';
import { useAuthStore } from '../../../stores/useAuthStore.js';

export default function UserMenu({ anchorElUser, settings, setAnchorElNav, setAnchorElUser }) {
  const { isAuthenticated, email } = useAuthStore();
  const { profilePicture } = useProfileStore();

  const { handleOpenUserMenu, handleCloseUserMenu } = useAppBar({
    setAnchorElNav,
    setAnchorElUser,
  });

  return (
    <>
      {isAuthenticated ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '40px',
          }}
        >
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar alt={email.toUpperCase()} src={`${profilePicture}`} />
            </IconButton>
          </Tooltip>
          <Menu
            disableScrollLock={true}
            sx={{ mt: { xs: '38px', sm: '40px', md: '45px' } }}
            id="user-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} value={setting} onClick={(e) => handleCloseUserMenu(e)}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      ) : null}
    </>
  );
}
