import { Link } from 'react-router-dom';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';

import {
  deleteById,
  deleteImage,
  getAdditionalImageUrlsPublicIds,
} from '../../services/fetch-utils.js';
import './PostCard.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../context/QueryContext.js';
import { deleteProduct } from '../../services/fetch-products.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthStore } from '../../stores/useAuthStore.js';
import usePostStore from '../../stores/usePostStore.js';
export default function PostCard({ id, post, posts, setPosts, products, setProducts }) {
  const { isAuthenticated, user, error, customerId } = useAuthStore();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const { setNewPostCreated } = useQuery();

  const { setLoading, setPostError } = usePostStore();

  const [deletedRowId, setDeletedRowId] = useState(null);

  const [openDialog, setOpenDialog] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (!user || !isAuthenticated || !customerId || error === 401 || error === 403) {
      return;
    }
  }, [user, isAuthenticated, customerId, error]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDelete = async () => {
    await handleDelete();
    handleCloseDialog();
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/auth/sign-in');
    }
  }, [isAuthenticated, navigate]);

  // delete the post and corresponding quota-tracking post from the database
  const handleDelete = async () => {
    navigate(`/dashboard/`);

    setIsDeleting(true);
    try {
      setDeletedRowId(id);
      // grab urls out of my database
      const postUrls = await getAdditionalImageUrlsPublicIds(id);

      // delete all images from cloudinary
      for (let i = 0; i < postUrls.length; i++) {
        await deleteImage(postUrls[i].public_id, postUrls[i].resource_type);
      }

      // delete corresponding quota-tracking post from database
      const postId = id; // The ID of the post to delete

      const matchingProduct = products.find((product) => product.post_id === postId);

      if (matchingProduct) {
        await deleteProduct(matchingProduct.id);

        // Update the products state to exclude the deleted product
        setProducts((prevProducts) =>
          prevProducts.filter((product) => product.id !== matchingProduct.id)
        );
      }

      // delete the post from my database
      await deleteById(id);

      // delete the post from state, so it doesn't show up on the page  /______________________________ this is from my old code which works- it is from a hook instead of context...
      const updatedPosts = posts.filter((post) => post.id !== id);
      setPosts(updatedPosts);

      // After successfully deleting the post from the database and updating local state:
      setNewPostCreated((prevState) => !prevState); // Toggle newPostCreated state after post deletion
      setIsDeleting(false);
      setLoading(false);
    } catch (e) {
      setIsDeleting(false);
      setPostError(e.message);

      if (e.code === 401 || e.code === 403) {
        useAuthStore.getState().handleAuthError(e.code, e.message);
      } else {
        // eslint-disable-next-line no-console
        console.error('Error deleting post:', e);
        useAuthStore.getState().setError(e.code);
        toast.error(`Error deleting post: ${e.message}`, {
          theme: 'colored',
          draggable: true,
          draggablePercent: 60,
          toastId: 'postCard-1',
          autoClose: false,
        });
      }
    }
  };

  const handleEditPost = () => {
    navigate(`/dashboard/edit/${id}`);
  };
  return (
    <div className={`post ${id === deletedRowId ? 'grayed-out' : ''}`} key={id}>
      <Link to={`/gallery/${id}`}>
        {post.image_url && (
          <img
            className="admin-prod-img"
            src={
              post.image_url.endsWith('.mp4')
                ? `${post.image_url.slice(0, -4)}.jpg`
                : post.image_url
            }
            alt="edit"
          />
        )}
      </Link>

      <span className="grid-s2 grid-e3 mobile-title">
        {post.title.length > 25 ? post.title.slice(0, 25) + '...' : post.title}
      </span>
      <Box className="title-price-container">
        <Typography variant="body1" className="mobile-title-desk">
          {post.title}
        </Typography>
        <Grid container>
          <Typography variant="body1" sx={{ textAlign: 'right' }}>
            ${Number(post.price).toLocaleString()}
            <span
              style={{
                color: 'lightgreen',
                fontWeight: 'bold',
                display: isMobile ? 'block' : 'inline',
              }}
            >
              {post.sold === true ? ' SOLD' : ''}
            </span>
          </Typography>
        </Grid>
      </Box>
      <Typography variant="body1" className="cat-desk">
        {post.category}
      </Typography>
      <Typography variant="body1" className="desc-desk">
        {post.description}
      </Typography>

      <div className="admin-prod-btn-cont grid-7">
        {
          <Button
            onClick={handleEditPost}
            sx={{ fontSize: '1rem' }}
            disabled={post.restricted ? post.restricted : false}
          >
            Edit
          </Button>
        }
        <Button onClick={handleOpenDialog} sx={{ fontSize: '1rem' }}>
          Delete
        </Button>
      </div>

      <Dialog
        open={openDialog}
        onClose={isDeleting ? undefined : handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deleting this post will remove it permanently. This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!isDeleting ? (
            <Box>
              {' '}
              <Button onClick={handleCloseDialog} color="primary" sx={{ fontSize: '1rem' }}>
                Cancel
              </Button>
              <Button
                onClick={handleConfirmDelete}
                color="primary"
                sx={{ fontSize: '1rem' }}
                autoFocus
              >
                Confirm
              </Button>
            </Box>
          ) : (
            <Typography
              color="primary"
              sx={{
                padding: '5px',
                borderRadius: '5px',
                width: '150px',
                textAlign: 'center',
              }}
            >
              Deleting...
            </Typography>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
