import React, { useEffect } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Button,
  Box,
  useMediaQuery,
} from '@mui/material';
import jake from './../../assets/jacob.jpg';
import kevin from './../../assets/kevin.jpg';
import tyler from './../../assets/tyler.jpg';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import './About.css';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { useTheme } from '@emotion/react';
import { useAuthStore } from '../../stores/useAuthStore.js';
import { useNavigate } from 'react-router-dom';

const developers = [
  {
    id: 1,
    name: 'Jake Doherty',
    role: 'Full Stack Developer',
    description: 'Full Stack Software Engineer | Musician | Maker | Renaissance Man',
    avatar: jake,
    link: 'https://www.linkedin.com/in/jacob-doherty1',
  },
  {
    id: 2,
    name: 'Kevin Nail',
    role: 'Full Stack Developer',
    description: 'Glass Artist | Full Stack Software Engineer | Drummer | Dudeist Priest',
    avatar: kevin,
    link: 'https://www.linkedin.com/in/kevinnail',
  },
  {
    id: 3,
    name: 'Tyler Watson',
    role: 'Full Stack Developer',
    description: 'Full-Stack Software Engineer | 𝔽𝕖𝕝𝕚𝕟𝕖 𝔽𝕒𝕥𝕙𝕖𝕣',
    avatar: tyler,
    link: 'https://www.linkedin.com/in/tylerwatson91',
  },
];

function About() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const { authenticateUser, isAuthenticated, error, signingOut, checkTokenExpiry } = useAuthStore();

  // authenticate and check tokens
  useEffect(() => {
    if (!isAuthenticated && !error && !signingOut) {
      authenticateUser();
    } else if (isAuthenticated) {
      // If we are authenticated, check token expiry
      checkTokenExpiry();
    }
  }, [isAuthenticated, error, authenticateUser, signingOut, checkTokenExpiry]);

  return (
    <Container maxWidth="lg" sx={{ pt: 10 }}>
      <Typography variant="h4" gutterBottom sx={{ textAlign: 'left' }}>
        About Our Team
      </Typography>
      <Grid container spacing={1}>
        {developers.map((dev) => (
          <Grid item xs={12} sm={6} md={4} key={dev.id}>
            <Card
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: (theme) => theme.palette.primary.light,
                borderRadius: (theme) => theme.spacing(1),
                gap: (theme) => theme.spacing(2),
                height: '100%',
                '& .MuiCardContent-root:last-child': {
                  padding: '0px',
                },
                animation: `cardfadeIn 2s ease-in-out ${dev.id - 1}s forwards`,
                opacity: 0,
              }}
            >
              <CardContent sx={{ padding: '0px' }}>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    component="img"
                    sx={{
                      width: 100,
                      height: 100,
                      borderRadius: 0,
                    }}
                    src={dev.avatar}
                    alt={dev.name}
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      ml: 1,
                      width: '100%',
                    }}
                  >
                    <Button
                      href={dev.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      endIcon={<LaunchOutlinedIcon />}
                    >
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: '1.2rem',
                          textAlign: 'left',
                          lineHeight: '.5rem',
                          width: '100%',
                          mt: '8px',
                        }}
                      >
                        {dev.name}
                      </Typography>
                    </Button>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        lineHeight: '1.5rem',
                        paddingLeft: 1,
                        textAlign: 'left',
                        color: 'text.secondary',
                      }}
                    >
                      {dev.role}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '0.7rem',
                        paddingLeft: 1,
                        textAlign: 'left',
                      }}
                    >
                      {dev.description}
                    </Typography>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* About Section */}
        <Grid item xs={12}>
          <Card
            variant="outlined"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: (theme) => theme.palette.primary.light,
              borderRadius: (theme) => theme.spacing(1),
              gap: (theme) => theme.spacing(2),
              width: 'min(100%)',
              mt: '16px',
            }}
          >
            <CardContent>
              <Box
                sx={{
                  background: 'linear-gradient(135deg, #2e7d222f 0%, #1c1c1c 100%)',
                  border: '2px solid rgba(46, 125, 50, 0.3)',
                  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
                  padding: '15px',
                  borderRadius: '15px',
                  margin: 'auto',
                }}
              >
                {isMobile ? (
                  <>
                    {' '}
                    {/* Fragment for mobile view */}
                    <Typography
                      variant="h4"
                      sx={{
                        textAlign: 'left',
                        mb: 2,
                        position: 'relative',
                        '::after': {
                          content: '""',
                          width: '50px',
                          height: '3px',
                          backgroundColor: 'green',
                          position: 'absolute',
                          bottom: '-8px',
                          left: 0,
                        },
                      }}
                    >
                      Our Story
                    </Typography>
                    <Typography
                      variant="span"
                      style={{
                        opacity: 0,
                        marginLeft: '0px',
                        fontSize: '1rem',
                        letterSpacing: '.2rem',
                        textAlign: 'left',
                        display: 'inline-block',
                        color: 'green',
                        fontWeight: 'bold',
                        textShadow: '2px 2px 2px #FFFFFF20',
                      }}
                      className="welcome-text"
                    >
                      {' '}
                      Crisis is often opportunity...
                    </Typography>
                  </>
                ) : (
                  <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography
                      variant="h4"
                      sx={{
                        textAlign: 'left',
                        mb: 2,
                        position: 'relative',
                        '::after': {
                          content: '""',
                          width: '50px',
                          height: '3px',
                          backgroundColor: 'green',
                          position: 'absolute',
                          bottom: '-8px',
                          left: 0,
                        },
                      }}
                    >
                      Our Story
                    </Typography>
                    <Typography
                      variant="span"
                      style={{
                        opacity: 0,
                        marginLeft: '40px',
                        fontSize: '1.5rem',
                        letterSpacing: '.2rem',
                        textAlign: 'left',
                        display: 'inline-block',
                        color: 'green',
                        position: 'relative',
                        textShadow: '2px 2px 2px #FFFFFF20',
                        top: '8px',
                      }}
                      className="welcome-text"
                    >
                      {' '}
                      Crisis is often opportunity...
                    </Typography>
                  </Box>
                )}

                <Typography variant="body1" paragraph align="left" sx={{ lineHeight: 1.75 }}>
                  {`The 3 of us had big dreams going to web development boot camp- then it closed on us 3/4 of the way through with no warning whatsoever. 
                  Instead of letting it get in our way, we came together to make something great.  We needed to code, the glass community needs more platforms, 
                  and this is our answer. While life/ work has taken us all down different paths for now, the work we started together remains at the heart and technical foundation 
                  of this platform. I've continued to build on our shared vision and am proud to present what At The Fire has become.  `}
                </Typography>

                <Typography
                  variant="h4"
                  sx={{
                    textAlign: 'left',
                    // fontWeight: 'bold',
                    mb: 2,
                    position: 'relative',
                    '::after': {
                      content: '""',
                      width: '50px',
                      height: '3px',
                      backgroundColor: 'green',
                      position: 'absolute',
                      bottom: '-8px',
                      left: 0,
                    },
                  }}
                >
                  My Goal
                </Typography>

                <Typography variant="body1" paragraph align="left" sx={{ lineHeight: 1.75 }}>
                  {`Back in the day, we had glasspipes.org.  Every day you excitedly looked at the home page to 
                  see what was new- it defined the day.  Do you feel that way opening social media?  Neither do we.   Hence,
                      At The Fire was born.    `}
                </Typography>
                <Typography variant="body1" paragraph align="left" sx={{ lineHeight: 1.75 }}>
                  {`We want to create a space to bring artists and collectors together while also helping artists run their 
                  business more effectively. Though the original team was a mix of collectors and artists, this remains a
                   community-driven effort—an homage to all of us glassblowers who work behind the flame, but also 
                   the welcoming space of a warm fire where everyone is invited. `}
                </Typography>

                <Typography variant="body1" paragraph align="left" sx={{ lineHeight: 1.75 }}>
                  The site is actively being worked on—I am currently in Beta Testing with select
                  users. If you wish to be on the waiting list, please do so through our sign up on
                  our{' '}
                  <span
                    onClick={() => navigate('/contact')}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Contact Page
                  </span>
                  . And please, reach out to help make this a better place. I always welcome
                  feedback, suggestions, or questions. Thank you for being here. I hope you enjoy
                  the platform. -Kevin
                </Typography>
                <Typography variant="body1" paragraph align="left" sx={{ lineHeight: 1.75 }}>
                  For the nerds:{' '}
                  <span
                    onClick={() => navigate('/about-project')}
                    style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Project Info
                  </span>
                </Typography>
              </Box>

              <Typography
                variant="h4"
                sx={{
                  margin: {
                    xs: '40px 5%',
                    sm: '40px 10%',
                    md: '40px 20%',
                    lg: '40px 30%',
                    letterSpacing: '.6rem',
                  },
                  textAlign: 'left',
                }}
                className="shimmer-text"
              >
                Welcome to
              </Typography>
              <Typography
                className="reenie-beanie-regular"
                sx={{
                  color: '#1f8e3d',
                  fontSize: '6rem',
                  textAlign: 'center',
                  fontFamily: 'Reenie Beanie',
                  position: 'relative',
                  top: '-50px',
                  textShadow: '5px 5px 15px  #FFFFFF20',
                }}
              >
                At The
              </Typography>
              <Typography
                className="reenie-beanie-regular"
                sx={{
                  color: '#1f8e3d',
                  fontSize: '8rem',
                  textAlign: 'center',
                  fontFamily: 'Reenie Beanie',
                  position: 'relative',
                  top: '-50px',
                  lineHeight: '1rem',
                  textShadow: '5px 5px 15px  #FFFFFF40',
                }}
              >
                Fire
              </Typography>
              <div className="loading-detail">
                <FlamePipe sx={{ zIndex: '500' }} />

                <div className="firepit">
                  <div className="fire">
                    <div></div>
                    {Array.from({ length: 16 }, (_, i) => (
                      <div key={i} className="spark"></div>
                    ))}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
