import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  Typography,
  Modal,
  ImageList,
  ImageListItem,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useGalleryPost } from '../../hooks/useGalleryPost.js';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import { useSwipeable } from 'react-swipeable';
import userDefaultImage from './../../assets/user.png';

export default function GalleryPostDetail() {
  const { id } = useParams();
  const { postDetail, imageUrls, loading, error } = useGalleryPost(id);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleThumbnailClick = (index) => setCurrentIndex(index);
  const navigate = useNavigate();

  const handleLogoNavigation = () => navigate(`/profile/${postDetail.sub}`);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < imageUrls.length - 1 ? prevIndex + 1 : prevIndex));
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext,
    onSwipedRight: handlePrevious,
  });
  if (error) return;
  return loading ? (
    <div className="loading-detail-2">
      <FlamePipe />
    </div>
  ) : (
    <Box
      sx={{
        paddingTop: '70px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: (theme) => theme.palette.primary.light,
          paddingX: (theme) => theme.spacing(2),
          paddingY: (theme) => theme.spacing(2),
          borderRadius: (theme) => theme.spacing(1),
          width: 'min(100%, 800px)',
        }}
      >
        <Box display="flex" alignItems="center" marginBottom={2}>
          <Button onClick={handleLogoNavigation} sx={{ marginRight: 2 }}>
            <Avatar
              src={postDetail.logo_image_url ? postDetail.logo_image_url : userDefaultImage}
              alt="site logo"
              variant="rounded"
            />
            <Typography variant="h6" sx={{ marginLeft: '1rem' }}>
              {postDetail.display_name}{' '}
            </Typography>
          </Button>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom={2}>
          <Typography>Category: {postDetail.category}</Typography>
          <Typography variant="h4" sx={{ marginLeft: '1rem' }}>
            {postDetail.title}{' '}
          </Typography>
          <Typography>${Number(postDetail.price).toLocaleString()}</Typography>
        </Box>
        <Typography marginBottom={2}>{postDetail.description}</Typography>

        {/* Main Content Area */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: imageUrls.length > 1 ? 'row' : 'column',

            gap: (theme) => theme.spacing(2),
          }}
        >
          {/* Thumbnails */}
          {imageUrls.length > 1 && (
            <ImageList
              sx={{
                width: '20%',
                maxHeight: 400,
                overflowY: 'auto',
              }}
              variant="standard"
              cols={1}
              gap={1}
            >
              {imageUrls.map((imageUrl, index) => (
                <ImageListItem
                  key={index}
                  sx={{
                    marginRight: '8px',
                    border: '1px solid transparent',
                    '&:hover': {
                      cursor: 'pointer',
                      borderColor: (theme) => theme.palette.primary.main,
                      borderWidth: '1px',
                      borderStyle: 'solid',
                      transition: 'border 0.4s ease-in',
                      boxSizing: 'border-box',
                    },
                  }}
                >
                  <img
                    src={imageUrl}
                    alt={`thumbnail-gallery-${index}`}
                    onClick={() => handleThumbnailClick(index)}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          )}

          {/* Main Image */}
          <Box sx={{ width: '80%', position: 'relative' }} onClick={() => setModalIsOpen(true)}>
            <img
              src={imageUrls[currentIndex]}
              alt={`post-${currentIndex}`}
              style={{ width: '100%', cursor: 'pointer' }}
            />
          </Box>
        </Box>
      </Box>

      {/* Modal Remains Unchanged */}
      <Modal open={modalIsOpen} onClose={() => setModalIsOpen(false)}>
        <Box
          {...swipeHandlers} // Attach swipe handlers here
          sx={{
            position: 'relative',
            maxWidth: '90%',
            maxHeight: '90vh',
            overflowY: 'auto',
            margin: '20px auto',
            padding: 2,
            '& img': {
              maxWidth: '90vh',
              maxHeight: '90vh',
              display: 'block',
              margin: '0 auto',
            },
          }}
        >
          <img
            src={imageUrls[currentIndex]}
            alt={`modal-post-${currentIndex}`}
            style={{ width: '100%' }}
          />
          <IconButton
            onClick={() => setModalIsOpen(false)}
            sx={{ position: 'absolute', top: 0, right: 0, backgroundColor: 'red' }}
          >
            <CloseIcon />
          </IconButton>
          {currentIndex > 0 && (
            <IconButton
              onClick={() => setCurrentIndex((prev) => prev - 1)}
              sx={{ position: 'absolute', left: 0, top: '50%' }}
            >
              <NavigateBeforeIcon />
            </IconButton>
          )}
          {currentIndex < imageUrls.length - 1 && (
            <IconButton
              onClick={() => setCurrentIndex((prev) => prev + 1)}
              sx={{ position: 'absolute', right: 0, top: '50%' }}
            >
              <NavigateNextIcon />
            </IconButton>
          )}
        </Box>
      </Modal>
    </Box>
  );
}
