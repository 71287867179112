import { Box, Button } from '@mui/material';
import React from 'react';

export default function SubscriptionButtons({ handleClickSubscribe }) {
  return (
    <Box
      className={'subscription-button-wrapper'}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: (theme) => theme.spacing(2),
      }}
    >
      <Button
        variant="contained"
        color="primary"
        // value="price_1O4urWGO3TmEVjN2Pr99av5S"
        value="price_1Qf39ZLJ67I09H9nclsU7AqW"
        onClick={handleClickSubscribe}
      >
        Choose Monthly Subscription $15/mth
      </Button>
      <Button
        variant="contained"
        color="primary"
        // value="price_1O4urWGO3TmEVjN2YVoPrEmu"
        value="price_1Qf3B4LJ67I09H9nT2dAhAtm"
        onClick={handleClickSubscribe}
      >
        Choose Yearly Subscription $150/yr
      </Button>
    </Box>
  );
}
