import { createContext, useContext, useEffect, useState } from 'react';
import { fetchGalleryPosts } from '../services/fetchPosts.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuthStore } from '../stores/useAuthStore.js';
const QueryContext = createContext();

const QueryProvider = ({ children }) => {
  const [query, setQuery] = useState('');
  const [galleryPosts, setGalleryPosts] = useState([]);
  const [error, setError] = useState(false);
  const [newPostCreated, setNewPostCreated] = useState(false);

  const [fetchError, setFetchError] = useState(null);

  const [queryLoading, setQueryLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setQueryLoading(true);
        const data = await fetchGalleryPosts();

        setGalleryPosts(data);
        setQueryLoading(false);
      } catch (e) {
        setFetchError('Failed to fetch gallery posts.');

        if (e.code === 401 || e.code === 403) {
          useAuthStore.getState().handleAuthError(e.code, e.message);
        } else {
          // eslint-disable-next-line no-console
          console.error('Error fetching gallery posts:', e);
          useAuthStore.getState().setError(e.code);
          toast.error(`Error fetching gallery posts: ${e.message}`, {
            theme: 'colored',
            draggable: true,
            draggablePercent: 60,
            toastId: 'queryContext-1',
            autoClose: false,
          });
        }
      }
    };

    fetchData();
  }, [newPostCreated]);

  const searchQuery = (query || '').toLowerCase();

  const filteredData = galleryPosts.filter((item) => {
    const itemTitle = item.title ? item.title.toLowerCase() : '';
    const itemArtist = item.display_name ? item.display_name.toLowerCase() : '';
    const itemDescription = item.description ? item.description.toLowerCase() : '';
    const itemCategory = item.category ? item.category.toLowerCase() : '';
    return (
      itemTitle.includes(searchQuery) ||
      itemArtist.includes(searchQuery) ||
      itemDescription.includes(searchQuery) ||
      itemCategory.includes(searchQuery)
    );
  });

  useEffect(() => {
    if (filteredData.length === 0) {
      setError(true);
    } else {
      setError(null);
    }
  }, [filteredData]);

  return (
    <QueryContext.Provider
      value={{
        query,
        error,
        setQuery,
        filteredData,
        setNewPostCreated,
        fetchError,
        setGalleryPosts,
        queryLoading,
      }}
    >
      {children}
    </QueryContext.Provider>
  );
};

const useQuery = () => {
  const context = useContext(QueryContext);
  if (context === undefined) {
    throw new Error('useQuery must be used within a QueryProvider');
  }
  return context;
};

export { QueryProvider, useQuery };
