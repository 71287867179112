import React from 'react';
import { Box, Button, Grid, Paper, Typography, useMediaQuery } from '@mui/material';
import '../../Subscription.css';
import LandingPageInfo from './LandingPageInfo.js';
import { useNavigate } from 'react-router-dom';
import PromotionalVideo from './PromotionalVideo.js';
import { useTheme } from '@emotion/react';
import { useSubscriptionStageManager } from '../../../../hooks/useSubscriptionStageManager.js';
import { useAuthStore } from '../../../../stores/useAuthStore.js';

export default function SubscriptionLandingPage() {
  const { setStage } = useSubscriptionStageManager();
  const { isAuthenticated } = useAuthStore();
  const navigate = useNavigate();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSignInToSubscribe = () => {
    navigate('/auth/sign-up');
  };

  const handleStageChange = () => {
    setStage(1);
    navigate('/subscription/form');
  };

  return (
    <Box sx={{ flexGrow: 1, p: isMobile ? 0 : 2, position: 'absolute', top: '70px' }}>
      <Typography variant="h4" textAlign={'left'} sx={{ borderBottom: '2px solid green' }}>
        Become a subscriber!
      </Typography>
      <Grid container spacing={4} alignItems="start" sx={{ padding: '20px' }}>
        {/* Text and Video Section */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            '&.MuiGrid-item': { paddingLeft: '15px' },
          }}
        >
          <PromotionalVideo />
          <Paper sx={{ padding: '15px 20px', mt: '50px' }}>
            {' '}
            <Typography sx={{ textAlign: 'left' }}>
              If you&apos;d like to take a deeper dive into how things work, check out our{' '}
              <span
                onClick={() => {
                  navigate('/user-guide');
                }}
                style={{ color: 'lightgreen', cursor: 'pointer' }}
              >
                User Guide
              </span>
              <br />
              <br />
              Click the button below to purchase a monthly or yearly subscription.
            </Typography>
          </Paper>
          {/* Action Button */}
          <Grid item xs={12} sx={{}}>
            <Box textAlign="center">
              {isAuthenticated ? (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleStageChange}
                  sx={{ fontSize: '1.5rem', mt: '25px' }}
                >
                  Purchase a Subscription
                </Button>
              ) : (
                <Button color="primary" variant="contained" onClick={handleSignInToSubscribe}>
                  Sign-up to Subscribe
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            overflow: 'hidden',
            '&.MuiGrid-item': { paddingLeft: '15px' },
          }}
        >
          <LandingPageInfo
            isMobile={isMobile}
            handleStageChange={handleStageChange}
            handleSignInToSubscribe={handleSignInToSubscribe}
          />
        </Grid>

        <Grid item xs={12}></Grid>
      </Grid>
    </Box>
  );
}
