import React, { useEffect } from 'react';
import './Gallery.css';
import { useQuery } from '../../context/QueryContext.js';
import UserGallery from './UserGallery.js';
import FlamePipe from '../FlamePipe/FlamePipe.js';
import { useAuthStore } from '../../stores/useAuthStore.js';

export default function Gallery() {
  const { filteredData, queryLoading } = useQuery();
  const { authenticateUser, isAuthenticated, error, signingOut, checkTokenExpiry } = useAuthStore();

  // authenticate and check tokens
  useEffect(() => {
    if (!isAuthenticated && !error && !signingOut) {
      authenticateUser();
    } else if (isAuthenticated) {
      // If we are authenticated, check token expiry
      checkTokenExpiry();
    }
  }, [isAuthenticated, error, authenticateUser, signingOut, checkTokenExpiry]);

  return (
    <>
      {queryLoading ? (
        <>
          <div className="loading-detail-2">
            <FlamePipe />
          </div>
        </>
      ) : (
        <div style={{ position: 'relative', top: '85px' }}>
          <UserGallery data={filteredData} />
        </div>
      )}
    </>
  );
}
